<script setup lang="ts">
import type { OptionSelect } from '@gem/uikit';
import type { ScreenType, TypographyFontOption, TypographyV2Attrs, TypographyV2Props } from '../../types';
import type { FontItem, FontName, TypographyProps, TypographyValue } from './types';
import type { ColorProps } from '../global-color/types';
import { isObjectEqual } from '../../helpers/common';
import TypographyFormEdit from './TypographyFormEdit.vue';
import TypographyStyleSelect from './TypographyStyleSelect.vue';
import { computed } from 'vue';
import { useGetFontWeightOptions } from './composables/useGetFontWeightOptions';
import { useGetFontWeightFromOptions } from './composables/useGetFontWeightFromOptions';
import type { TypographyFamily } from '@gem/common';

type Props = {
  id: string;
  tag?: string;
  sourceTypo?: Record<string, TypographyProps>;
  baseTypo?: TypographyV2Props;
  sourceFont?: Record<string, FontItem>;
  isCustom?: boolean;
  value?: TypographyValue;
  overlayContainer?: string;
  typos?: { id: string; name: string; value?: string }[];
  globalStyleFont?: Record<string, FontItem>;
  currentScreen?: ScreenType;
  hiddenSetting?: Record<keyof TypographyV2Attrs, boolean>;
  searchQuery?: string;
  currentGlobalStyleColor: any;
  globalColors: ColorProps[];
  sectionId: string;
  componentUid: string;
  currentComponentSetting?: any;
  shopId?: string;
  shopStore?: any;
  editorStore?: any;
  listCustomFont?: unknown[];
  fontBackup?: FontItem;
  allFonts: FontItem[];
  themeFonts?: Partial<Record<FontName, FontItem>>;
  fontOptions: TypographyFontOption[];
  fontUploads?: TypographyFontOption[];
  disableCollapse?: boolean;
  isShowResponsive?: boolean;
};

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value: Props['value']): void;
  (e: 'controlOnChange', controlId: string, value: Props['value']): void;
  (e: 'changeScreen', screenId: ScreenType): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'changeTypo', type: string, customFontSizes?: Record<string, string>): void;
  (e: 'saveColor', newColorList: {}): void;
}>();

const props = defineProps<Props>();

// ======= Computed =======
const allFonts = computed(() => props.allFonts);

const checkExistFont = computed(() => {
  return allFonts.value?.some((font) => {
    if (typeof props.value?.custom?.fontFamily === 'string') {
      return props.value?.custom?.fontFamily === font?.family;
    }
    return props.value?.custom?.fontFamily?.value === font?.family;
  });
});

const displayValue = computed<TypographyV2Props | undefined>(() => {
  const textShadow = props.value?.custom?.textShadow ?? {
    angle: 37,
    blur: '2px',
    color: 'rgba(0, 0, 0, 0.6)',
    distance: '4px',
    type: 'custom',
  };
  const hasShadowText = props.value?.custom?.hasShadowText ?? false;
  if (props.isCustom) {
    if (!checkExistFont.value && props.fontBackup) {
      const fontDisplay =
        props.fontBackup.type === 'theme'
          ? ({ value: props.fontBackup.family, type: 'theme' } as TypographyFamily)
          : props.fontBackup.family;

      return {
        ...props.value?.custom,
        hasShadowText,
        fontFamily: fontDisplay,
        textShadow,
      };
    }
    return {
      ...props.value?.custom,
      hasShadowText,
      textShadow,
    };
  }

  let baseTypo = { ...props.baseTypo };
  if (props.value?.custom && props.value?.custom?.fontSize) {
    baseTypo = {
      ...baseTypo,
      fontSize: props.value.custom.fontSize,
    };
  }

  return baseTypo;
});

const displayAttr = computed(() => {
  return props.value?.attrs;
});
const typoName = computed(() => {
  return props.typos?.find((typo) => typo.id === props.value?.type)?.name ?? 'Custom';
});

const { getFontWeightOptions } = useGetFontWeightOptions({
  themeFonts: props.themeFonts,
  fontBackup: props.fontBackup,
});

const fontWeightOptions = computed<{ label: string; value: string }[] | undefined>(() =>
  getFontWeightOptions(allFonts.value, displayValue?.value?.fontFamily),
);

const handleSaveColor = (newColorList: {}) => emit('saveColor', newColorList);
const handleChangeField = (
  name: string,
  value: any,
  propType: 'attrs' | 'custom' = 'custom',
  type: 'controlOnChange' | 'controlChange',
) => {
  const currentPropValue = propType === 'attrs' ? displayAttr.value : displayValue.value;
  const newValue: TypographyValue = {
    ...props.value,
    [propType]: {
      ...currentPropValue,
      [name]: value,
    },
  };

  if (name === 'fontFamily' && propType === 'custom') {
    const font = allFonts.value?.find((item) => item.family === value);
    const fontVariants = font?.variants;
    const fontWeightOptions = getFontWeightOptions(allFonts.value, newValue.custom?.fontFamily);
    const fontWeight = useGetFontWeightFromOptions(newValue.custom, fontWeightOptions);

    newValue.custom = {
      ...newValue.custom,
      fontVariants,
      fontWeight,
    };
  }

  const { fontVariants, ...restCustom } = newValue.custom ?? {};
  if (isObjectEqual(props.baseTypo, restCustom) && props.value?.type) {
    emit('changeTypo', props.value.type);
  } else if (name === 'fontSize' && props.value?.type) {
    emit('changeTypo', props.value.type, value);
  } else if (type === 'controlChange') {
    emit('controlChange', props.id, newValue);
  } else {
    emit('controlOnChange', props.id, newValue);
  }
};
const handleControlChange = (name: string, value: any, propType: 'attrs' | 'custom' = 'custom') => {
  handleChangeField(name, value, propType, 'controlChange');
};
const handleControlOnChange = (name: string, value?: any, propType: 'attrs' | 'custom' = 'custom') => {
  handleChangeField(name, value, propType, 'controlOnChange');
};
const handleChangeTypo = (_: OptionSelect, newType: any) => {
  if (newType !== props.value?.type) {
    emit('changeTypo', newType);
  }
};
const handleClickSubAction = (type: string) => {
  emit('onClickSubAction', type);
};
</script>

<template>
  <div class="flex w-full flex-col gap-16">
    <div class="flex items-center justify-between">
      <span class="text-12 text-text-dark-300 font-regular whitespace-nowrap">Styles</span>
      <div class="max-w-input-horizontal w-full">
        <TypographyStyleSelect
          :options="typos"
          data-test="editor-control-typo-select"
          :active-id="value?.type"
          @on-click-sub-action="handleClickSubAction"
          @select="handleChangeTypo">
          <template #button-content>
            <span
              class="text-12 truncate whitespace-nowrap"
              :class="{
                italic: isCustom,
              }">
              {{ typoName }}{{ isCustom ? '*' : '' }}
            </span>
          </template>
        </TypographyStyleSelect>
      </div>
    </div>

    <TypographyFormEdit
      :font-options="fontOptions"
      :value="displayValue"
      :attrs="displayAttr"
      :overlay-container="overlayContainer"
      :current-screen="currentScreen"
      :hidden-setting="hiddenSetting"
      :font-weight-options="fontWeightOptions"
      :search-query="searchQuery"
      :current-global-style-color="currentGlobalStyleColor"
      :global-colors="globalColors"
      :section-id="sectionId"
      :component-uid="componentUid"
      :current-component-setting="currentComponentSetting"
      :global-style-font="globalStyleFont"
      :editor-store="editorStore"
      :shop-id="shopId"
      :shop-store="shopStore"
      :font-uploads="fontUploads"
      :theme-fonts="themeFonts"
      :tag="tag"
      :disable-collapse="disableCollapse"
      :is-show-responsive="isShowResponsive"
      @save-color="handleSaveColor"
      @change="handleControlChange"
      @on-change="handleControlOnChange"
      @change-screen="$emit('changeScreen', $event)" />
  </div>
</template>
